import React, { useState } from 'react';

function CrowFieldOnOff(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { htmlattr } = attr || {};

	if (!value || value === '') {
		value = false;
	}

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			<input
				type='checkbox'
				checked={value}
				onChange={(e) => {
					props.setValue(!value);
				}}
				{...htmlattr}
			/>
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['choice.on.off'] = CrowFieldOnOff;
export default CrowFieldOnOff;