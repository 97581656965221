import React from 'react';

function CrowFieldSmall(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { htmlattr } = attr || {};

	if (!value) {
		value = '';
	}

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			<input
				type="text"
				value={value}
				onChange={(e) => {
					props.setValue(e.target.value);
				}}
				{...htmlattr}
			/>
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['text.small'] = CrowFieldSmall;
export default CrowFieldSmall;