import React from 'react';
import ReactDOM from 'react-dom';

import Select from 'react-select';
import {
	Breadcrumb,
	Form,
} from 'semantic-ui-react';

import CrowForm from 'Crow/Form/Form.js';
import CrowAdminRelationOTO from 'Crow/Form/Admin/OTO.js';
import CrowAdminRelationOTM from 'Crow/Form/Admin/OTM.js';

import CrowFieldDropdown from 'Crow/Form/Field/Choice/Dropdown.js';
import CrowFieldOnOff from 'Crow/Form/Field/Choice/OnOff.js';
import CrowFieldRadio from 'Crow/Form/Field/Choice/Radio.js';
import CrowFieldID from 'Crow/Form/Field/Hidden/ID.js';
import CrowFieldFloat from 'Crow/Form/Field/Number/Float.js';
import CrowFieldInt from 'Crow/Form/Field/Number/Int.js';
import CrowFieldLarge from 'Crow/Form/Field/Text/Large.js';
import CrowFieldSmall from 'Crow/Form/Field/Text/Small.js';

import {
	getModel1Name,
	getModel2Name,
	parseEntityName,
	toLowerCamelCase,
	toSnakeCase,
} from 'Crow/Common/Crow';

import kali from 'kali';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

class CrowFormAdmin extends React.Component {
	constructor(props) {
		super(props);

		let router = props.router;
		let params = router.params;

		this.state = {
			formHost: `https://crow-server.touchsource.com`,

			execInfo: {},
			execSchema: {},

			execList: [],
			execData: {},

			modelList: [],
			modelData: {},
		}
	}

	componentDidMount() {
		let router = this.props.router;
		let params = router.params;

		this.fetchCrowExecModelList();

		// preload admin OTO/OTM field
		if (!window.CrowFormField) {
			window.CrowFormField = {};
		}
		window.CrowFormField['admin.relation.oto'] = CrowAdminRelationOTO;
		window.CrowFormField['admin.relation.otm'] = CrowAdminRelationOTM;

		window.CrowFormField['choice.dropdown'] = CrowFieldDropdown;
		window.CrowFormField['choice.on.off'] = CrowFieldOnOff;
		window.CrowFormField['choice.radio'] = CrowFieldRadio;
		window.CrowFormField['hidden.id'] = CrowFieldID;
		window.CrowFormField['number.float'] = CrowFieldFloat;
		window.CrowFormField['number.int'] = CrowFieldInt;
		window.CrowFormField['text.large'] = CrowFieldLarge;
		window.CrowFormField['text.small'] = CrowFieldSmall;

		window.React = React;
		window.ReactDOM = ReactDOM;

		let fn = (_this, e) => {
			let { entity, edit } = e.detail;
			let m2 = getModel2Name(entity.name);

			let router = _this.props.router;
			let params = router.params;
			let prev = `${params.model}.${params.id}`;

			if (edit) {
				router.redirect({
					to: `/${params.exec}/${toSnakeCase(m2)}/${entity.value}`,
				});

				return;
			}

			router.redirect({
				to: `/${params.exec}/${toSnakeCase(m2)}/new?prev=${window.location.pathname}`,
			});
		};
		document.body.addEventListener('router::redirect', fn.bind(null, this));
	}

	componentDidUpdate(prevProps) {
		let prevRouterParams = prevProps.router.params;
		let currRouterParams = this.props.router.params;

		if (prevRouterParams !== currRouterParams) {
			if (prevRouterParams.exec !== currRouterParams.exec) {
				this.fetchExecInfoAndSchema();
			}

			if (prevRouterParams.model !== currRouterParams.model) {
				this.fetchExecModelList(currRouterParams.model);
			}

			if (prevRouterParams.id !== currRouterParams.id) {
				// created, need to refetch list
				if (!prevRouterParams.id && currRouterParams.id) {
					this.fetchExecModelList(currRouterParams.model);
				}
			}
		}
	}

	uri() {
		const execData = this.state.execData;
		let uri = `http://${execData.host}:${execData.port}`;
		if (execData.secure) {
			uri = uri.replace('http://', 'https://');
		}

		return uri;
	}

	req(action, method, uri, opts = {}) {
		console.log('ACTION:', action);
		console.log('URI:', uri, method);

		new kali(opts)[method](uri, {
			success: (_kali, res, crowRes) => {
				if (action === 'FETCH_CROW_EXEC_LIST_DATA') {
					let execList = crowRes.data;
					this.setState({
						execList,
						execData: execList[0],
					});

					let {
						params
					} = this.props.router;

					if (params.exec) {
						let exec = this.dataByField(execList, 'name', params.exec);
						this.setState({
							execData: exec,
							modelList: [],
							modelData: {},
						});
					}

					this.fetchExecInfoAndSchema();
				}

				if (action === 'FETCH_EXEC_INFO') {
					this.setState({
						execInfo: crowRes,
						modelList: [],
						modelData: {},
					});

					let {
						params
					} = this.props.router;

					if (params.model) {
						this.fetchExecModelList(params.model);
					}
				}

				if (action === 'FETCH_EXEC_SCHEMA') {
					this.setState({
						execSchema: crowRes,
					});
				}

				if (action === 'FETCH_EXEC_MODEL_LIST_DATA') {
					this.setState({
						modelList: crowRes.data,
						modelData: crowRes.data[0],
					});
				}
			},

			failure: (_kali, err) => {
				console.log(_kali);
				console.log(err);

				// debugger;
			}
		}, true);
	}

	fetchCrowExecModelList() {
		console.log('fetchCrowExecModelList');

		let uri = `${this.state.formHost}/r/list/exec`;
		this.req('FETCH_CROW_EXEC_LIST_DATA', 'post', uri);
	}

	fetchExecInfoAndSchema() {
		console.log('fetchExecInfo');

		let uri = `${this.uri()}/i/app`;
		this.req('FETCH_EXEC_INFO', 'get', uri);

		console.log('fetchExecSchema');

		let uri2 = `${this.uri()}/i/db`;
		this.req('FETCH_EXEC_SCHEMA', 'get', uri2);
	}

	fetchExecModelList(model) {
		if (!model) {
			return;
		}
		console.log('fetchExecModelList');

		let uri = `${this.uri()}/r/list/${toLowerCamelCase(model)}`;
		this.req('FETCH_EXEC_MODEL_LIST_DATA', 'post', uri);
	}

	dataByField(data = [], key = '', val = false) {
		for (let i = 0, l = data.length; i < l; i++) {
			for (let [k, v] of Object.entries(data[i])) {
				if (k === key) {
					if (v === val) {
						return data[i];
					}
				}
			}
		}

		return false;
	}

	convertExecSchemaToForm() {
		let {
			params,
		} = this.props.router;

		let m1 = toSnakeCase(params.model || '');
		let id = params.id || 0;

		if (!m1) {
			return false;
		}

		const {
			execInfo,
			execSchema,
		} = this.state;

		if (!execInfo || !execSchema) {
			return false;
		}

		if (Object.keys(execInfo).length === 0 || Object.keys(execSchema).length === 0) {
			return false;
		}

		let parentRelations = [];
		try {
			if (execInfo['parent_relations'][m1]) {
				parentRelations = execInfo['parent_relations'][m1];
			}
		} catch (err) { }

		let otoRelations = [];
		let otmRelations = [];
		if (parentRelations.length > 0) {
			parentRelations.forEach((relation) => {
				let m = `${m1}_to_${relation}_map`;
				let relationType = execInfo['map_model_relations'][m];

				if (relationType === 'OTO') {
					otoRelations.push(relation);
				}

				if (relationType === 'OTM') {
					otmRelations.push(relation);
				}
			});
		}

		let forms = {
			'form.1': [
				'page.1'
			]
		};

		let pages = {
			'page.1': [
				'group.1'
			]
		};

		let groups = {
			'group.1': []
		};

		let fields = {};
		execSchema[m1].forEach((field) => {
			let entityKey = `${m1}.${field.field_name}`;

			fields[entityKey] = {
				fieldType: 'text.small',
				attr: {},
			};
			if (field.field_name === 'id') {
				fields[entityKey].attr.readonly = true;

				if (!id) {
					return;
				}
			}

			groups['group.1'].push(entityKey);
		});

		if (m1 && Number(id) > 0) {
			if (otoRelations.length !== 0) {
				pages['page.1'].push(['relations.oto', {
					fieldType: 'admin.relation.oto',
				}]);
				groups['relations.oto'] = [];

				otoRelations.forEach((model2Name) => {
					groups['relations.oto'].push(model2Name);
					groups[model2Name] = [];

					let entityKey = `${m1}.${model2Name}.*`;
					fields[entityKey] = {
						fieldType: 'text.small',
					};
					groups[model2Name].push(entityKey);
				});
			}

			if (otmRelations.length !== 0) {
				pages['page.1'].push(['relations.otm', {
					fieldType: 'admin.relation.otm',
				}]);
				groups['relations.otm'] = [];

				otmRelations.forEach((model2Name) => {
					groups['relations.otm'].push(model2Name);
					groups[model2Name] = [];

					let entityKey = `${m1}.${model2Name}.*`;
					fields[entityKey] = {
						fieldType: 'text.small',
					};
					groups[model2Name].push(entityKey);
				});
			}
		}

		let flatForm = {
			forms: forms,
			pages: pages,
			groups: groups,
			fields: fields
		};

		return flatForm;
	}

	renderBreadcrumbs() {
		let {
			params
		} = this.props.router;

		let selectedExec = false;
		if (params.exec) {
			selectedExec = params.exec;
		}

		let selectedModel = false;
		if (params.model) {
			selectedModel = params.model;
		}

		let {
			execInfo,
			execList,
			modelList,
		} = this.state;

		let execModelList = [];
		if (execInfo.models) {
			execModelList = execInfo.models;
		}

		let execIndex = false;
		let execOptions = execList.map((exec, i) => {
			if (params.exec === exec.name) {
				execIndex = i;
			}

			return {
				value: exec,
				label: exec.name,
			}
		});

		let modelIndex = false;
		let modelOptions = execModelList.map((model, i) => {
			if (toSnakeCase(params.model || '') === toSnakeCase(model)) {
				modelIndex = i;
			}

			return {
				value: model,
				label: model,
			}
		});

		let idIndex = false;
		let idOptions = modelList.map((model, i) => {
			if (Number(params.id) === model.id) {
				idIndex = i;
			}

			let schema = this.state.execSchema[toSnakeCase(params.model)];
			let col2 = schema[1] || { field_name: '' };
			let col3 = schema[2] || { field_name: '' };

			let obj = {
				id: model.id,
				name: model[col2.field_name],
				col3: model[col3.field_name],
			};

			return {
				value: model.id,
				label: JSON.stringify(obj),
			};
		});

		return (
			<div
				className='breadcrumbs'
			>
				<Breadcrumb>
					<Breadcrumb.Section
						as='div'
						className='section-exec'
					>
						<Select
							className='breadcrumb-select'
							placeholder={'EXEC:'}
							autoFocus={!selectedExec}
							isSearchable={true}
							value={execOptions[execIndex] || false}
							onChange={(e) => {
								let exec = e.value;

								let change = true;
								if (exec.name === selectedExec) {
									change = false;
								}

								this.setState({
									execData: exec,
								});

								if (!change) {
									return;
								}

								this.props.router.redirect({
									to: `/${exec.name}`,
								});
							}}
							onBlur={(e) => {

							}}
							options={execOptions}
						/>
					</Breadcrumb.Section>

					<Breadcrumb.Divider
						icon='right chevron'
					/>
					<Breadcrumb.Section
						as='div'
						className='section-model'
					>
						<Select
							className='breadcrumb-select'
							placeholder={'MODEL:'}
							isDisabled={!selectedExec}
							isSearchable={true}
							value={modelOptions[modelIndex] || false}
							onChange={(e) => {
								let model = e.value;

								let change = true;
								if (model === selectedModel) {
									change = false;
								}

								if (!change) {
									return;
								}

								this.props.router.redirect({
									to: `/${selectedExec}/${model}`,
								});
							}}
							onBlur={(e) => {

							}}
							options={modelOptions}
						/>
					</Breadcrumb.Section>

					<Breadcrumb.Divider
						icon='right chevron'
					/>
					<Breadcrumb.Section
						as='div'
						className='section-id'
					>
						<Select
							className='breadcrumb-select'
							placeholder={`RECORD:`}
							isDisabled={!selectedExec || !selectedModel}
							isSearchable={true}
							isClearable={true}
							value={idOptions[idIndex] || false}
							onChange={(e, { action }) => {
								if (action === 'clear') {
									this.props.router.redirect({
										to: `/${selectedExec}/${selectedModel}`,
									});

									return;
								}

								let id = e.value;
								this.props.router.redirect({
									to: `/${selectedExec}/${selectedModel}/${id}`,
								});
							}}
							onBlur={(e) => {
							}}
							options={idOptions}
						/>
					</Breadcrumb.Section>
				</Breadcrumb>
			</div>
		)
	}

	render() {
		let router = this.props.router;
		let params = router.params;

		let flatForm = this.convertExecSchemaToForm();

		return (
			<>
				<div
					style={{
						height: '100%',
					}}
				>
					{this.renderBreadcrumbs()}

					{flatForm &&
						<CrowForm
							formHost={this.state.formHost}
							execHost={this.uri()}

							// m1={params.model}
							// id={Number(params.id) || 0}

							form={Object.assign({}, flatForm, {
								m1: params.model,
								id: Number(params.id) || 0,
								exec_id: this.state.execData.id,
							})}

							customEntity={(entity) => {
								if (entity.type !== 'FIELD') {
									return entity;
								}

								if (entity.name.match(/(created|updated)_at$/)) {
									return Object.assign({}, entity, {
										fieldType: 'custom.common.date.datetime',
										attr: {
											...entity.attr || {},
											htmlattr: {
												...(entity.attr || {}).htmlattr || {},
												readOnly: true,
											},
										}
									});
								}

								// let m1 = getModel1Name(entity.name);
								// if (entity.name === `${m1}.id`) {
								// 	entity.fieldType = 'hidden.id';
								// 	return;
								// }

								if (~entity.name.indexOf('deleted_at')) {
									return Object.assign({}, entity, {
										skip: true,
									});
								}

								let { m1, m2, id, col } = parseEntityName(entity.name);
								let table = m1;
								if (m2) {
									table = `${m1}_to_${m2}_map`;
								}
								if (this.state.execSchema[toSnakeCase(table)]) {
									let schema = this.state.execSchema[toSnakeCase(table)];
									let col = entity.name.split('.').pop();

									schema.forEach((schemaCol) => {
										if (schemaCol['field_name'] === col) {
											switch (schemaCol['go_data_type']) {
												case '*bool':
												case 'bool':
													entity.fieldType = 'choice.on.off';
													break;
												case '*int':
												case 'int':
												case '*int64':
												case 'int64':
												case '*float32':
												case 'float32':
												case '*float64':
												case 'float64':
													entity.fieldType = 'number.int';
													break;
												case '*string':
												case 'string':
													break;
												case '*time.Time':
												case 'time.Time':
													entity.fieldType = 'custom.common.date.datetime';
													break;
												case '[]byte':
													entity.fieldType = 'text.large';
													break;
											}

											if (schemaCol['go_data_type'][0] === '*') {
												entity.nullable = true;
											}
										}
									});
								}

								return entity;
							}}

							renderEntity={(entity, elm) => {
								let label = (entity.attr || {}).label || entity.name;

								if (entity.type === 'FORM') {
									return (
										<Form
											className='admin-form'
											style={{
												margin: '5px',
												padding: '5px',
												border: '1px dashed #efefef',
											}}
										>
											<div
												className='admin-form-label'
												style={{
													fontWeight: 'bold',
												}}
											>
												FORM:
											</div>
											{elm}
										</Form>
									)
								}

								if (entity.type !== 'FIELD') {
									return (
										<div>
											{elm}
										</div>
									)
								}

								if (entity.type === 'FIELD') {
									if (entity.skip) {
										return '';
									}

									if (entity.orgType && entity.orgType !== 'FIELD') {
										return (
											<div>
												{elm}
											</div>
										)
									}

									let tmp = entity;

									let entityParents = [entity.name];
									while (tmp.parent) {
										entityParents.unshift(tmp.parent.name);
										tmp = tmp.parent;
									}
									entityParents = (
										<ul
											style={{
												margin: '5px',
												padding: 0,
												listStyle: 'none',
											}}
										>
											{entityParents.map((entityName) => {
												return (
													<li
														key={entityName}
														style={{
															display: 'inline',
															padding: '0 5px 0 0',
														}}
														onClick={(e) => {
															console.log(entityName)
														}}
													>{entityName}</li>
												)
											})}
										</ul>
									)

									if (entity.parent.name === 'relations.otm') {
										return (
											<Form.Field>
												{elm}
											</Form.Field>
										)
									}

									return (
										<Form.Field>
											{/* {entityParents} */}
											<div><strong>{label}:</strong></div>
											{elm}
										</Form.Field>
									)
								}
							}}

							onSave={(crowRes) => {
								// if (~crowRes.modes.indexOf('update')) {
								// 	this.setState({
								// 		newValues: {},
								// 		modValues: {},
								// 	});
								// }

								if (~crowRes.modes.indexOf('create')) {
									let newID = crowRes.data[0].id;
									if (newID) {
										let to = `${window.location.pathname}/${newID}`;

										let s = window.location.search;
										s.replace('?', '').split('&').forEach((segment) => {
											let parts = segment.split('=');
											if (parts[0] === 'prev') {
												to = parts[1];
											}
										});

										this.props.router.redirect({
											to,
										});
									}

									let data = crowRes.data;
									data.forEach((d) => {
										if (d['_crow_']) {
											let m1 = d['_crow_']['m1'];
											let m2 = d['_crow_']['m2'];

											if (m1 && m2) {
												return window.location.reload();
											}
										}
									})
								}
							}}

						// onDelete={(crowRes) => {
						// 	this.props.router.redirect({
						// 		to: `/${selectedExec}/${selectedModel}`,
						// 	});
						// }}
						/>
					}
				</div>
			</>
		)
	}
}

export default CrowFormAdmin;