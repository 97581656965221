import React from 'react';

function CrowFieldFloat(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { htmlattr } = attr || {};

	if (!value || value === '') {
		value = 0;
	}

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			<input
				type='float'
				value={value}
				onChange={(e) => {
					props.setValue(e.target.value);
				}}
				{...htmlattr}
			/>
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['number.float'] = CrowFieldFloat;
export default CrowFieldFloat;