import React from 'react';

function CrowFieldDropdown(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { data = [], htmlattr } = attr || [];

	if (!data || (Array.isArray(data) && data.length === 0)) {
		// load dynamic data
	}

	if (typeof data === 'string') {
		// AJAX call to fetch data
	}

	if (!value) {
		value = '';
	}

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			<select
				className='standard-select-crow-field'
				name={field.name}
				value={value}
				onChange={(e) => {
					props.setValue(e.target.value);
				}}
				{...htmlattr}
			>
				{data.map((entry, i) => {
					return (
						<option
							key={i}
							value={entry.val}
						>
							{entry.key}
						</option>
					)
				})}
			</select>
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['choice.dropdown'] = CrowFieldDropdown;
export default CrowFieldDropdown;