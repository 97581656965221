import React from 'react';

function CrowFieldLarge(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { htmlattr } = attr || {};

	if (!value) {
		value = '';
	}

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			<textarea
				value={value}
				onChange={(e) => {
					props.setValue(e.target.value);
				}}
				cols={35}
				rows={10}
				{...htmlattr}
			/>
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['text.large'] = CrowFieldLarge;
export default CrowFieldLarge;