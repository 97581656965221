import React from 'react';

import Select from 'react-select';

import CrowFormEntity from 'Crow/Form/Entity.js';

import {
	parseEntityName,
	getModel2Name,
} from 'Crow/Common/Crow';

class CrowAdminRelationOTM extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			otmFields: {},
		};
	}

	componentDidMount() {
		console.log('OTM_MOUNT');
		console.log(this.props);

		this.buildFields();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.buildFields();
		}
	}

	buildFields() {
		let fields = {};
		for (let [key, val] of Object.entries(this.props.value)) {
			let { m1, m2, id, col } = parseEntityName(key);
			let relKey = `${m1}.${m2}`;

			if (!fields[relKey]) {
				fields[relKey] = {};
			}

			if (!fields[relKey][id]) {
				fields[relKey][id] = {};
			}

			fields[relKey][id][col] = val.value;
		}

		this.setState({
			otmFields: fields,
		});
	}

	renderOTM(m2, data) {
		let {
			form,
		} = this.props;

		let m1 = form.props.form.m1;

		let ids = [];
		Object.keys(this.state.otmFields).forEach((field, i) => {
			let { m2: _m2, id } = parseEntityName(field);
			if (_m2 !== m2) {
				return;
			}

			if (!~ids.indexOf(id)) {
				ids.push(id);
			}
		});

		let options = data[m2].map((entry, i) => {
			return {
				value: entry.val,
				label: entry.key,
			}
		}).filter((entry) => {
			if (m1 === m2 && entry.value === form.props.form.id) {
				console.log('SKIP:', entry);
				return;
			}

			return entry;
		});

		let defaultOptions = options.filter((entry) => {
			if (~ids.indexOf(entry.value)) {
				return entry;
			}

			return;
		});

		let alias = "";
		if (m1 === m2) {
			alias = "(ALIAS)"
		}

		return (
			<React.Fragment
				key={m2}
			>
				<div><strong>OTM &gt; {m2.toUpperCase()}{alias}:</strong></div>
				{/* <div>
					<button
					// onClick={this.handleCreateClick.bind(this, field)}
					>create</button>
				</div> */}

				<Select
					placeholder={`Choose a OTM Relation`}
					isMulti={true}
					isSearchable={true}
					// defaultValue={defaultOptions}
					options={options}
					onChange={(e, { option }) => {
						let idCol = `${m2}_id`;
						if (m1 === m2) {
							idCol = 'alias_id';
						}

						let name = `${m1}.${m2}.${option.value}.${idCol}`;
						form.setValue(name, option.value);
					}}
				/>
			</React.Fragment>
		)
	}

	render() {
		let {
			form,
		} = this.props;

		let flatForm = form.state.form.flatForm;
		let relationData = flatForm.relation_data;

		return (
			<div
				style={{
					marginTop: '5px',
					padding: '5px',
					backgroundColor: '#efefef',
					border: '1px solid #333',
					// overflowX: 'scroll',
				}}
			>
				{Object.keys(this.state.otmFields).map((field, i) => {
					let cols = [];
					for (let [key, val] of Object.entries(this.state.otmFields[field])) {
						cols = Object.keys(val).map((s) => {
							return {
								Header: s,
								accessor: s,
							}
						})
						// cols.shift();

						break;
					}

					if (cols.length === 0) {
						return 'no_relation';
					}

					let empty = false, ids = Object.keys(this.state.otmFields[field]);
					if (ids.length === 1 && Number(ids[0]) === 0) {
						empty = true;
					}

					return (
						<React.Fragment
							key={field}
						>
							{i !== 0 &&
								<hr />
							}

							{this.renderOTM(getModel2Name(`${field}.id`), relationData)}
							{(!empty) &&
								<table>
									<thead>
										<tr>
											{cols.map((col) => {
												return (
													<th
														key={col.Header}
													>{col.Header}</th>
												)
											})}
										</tr>
									</thead>

									<tbody>
										{Object.keys(this.state.otmFields[field]).map((id) => {
											let pendingDeletes = [];
											Object.keys(form.state.delValues || {}).forEach((name) => {
												let tmp = name.split('.');
												tmp.pop();

												pendingDeletes.push(tmp.join('.'));
											});

											if (~pendingDeletes.indexOf(`${field}.${id}`)) {
												return '';
											}

											return (
												<tr
													key={id}
												>
													{cols.map((col) => {
														let value = this.state.otmFields[field][id][col.accessor];

														let name = `${field}.${id}.${col.accessor}`;
														let entity = this.props.value[name];

														let elm = (
															<CrowFormEntity
																form={form}
																entity={entity}
																getValue={() => {
																	return form.getValue(name);
																}}
																setValue={(name, value) => {
																	form.setValue(name, form.getValue(name), true);

																	return form.setValue(name, value);
																}}
																getStatus={this.props.getStatus}
																setStatus={this.props.setStatus}
															/>
														)

														let td = (
															<td
																key={col.accessor}
															>
																{elm}
															</td>
														)

														if (~col.accessor.indexOf('_id')) {
															td = (
																<td
																	key={col.accessor}
																	className={`delete-wrapper`}
																	onClick={(e) => {
																		if (e.shiftKey) {
																			let deleteConfirmed = window.confirm("Delete Relation?");
																			if (deleteConfirmed) {
																				return form.setValue(name, 'CROW::DELETE');
																			}
																		}
																	}}
																>
																	{elm}
																</td>
															)
														}

														return td;
													})}
												</tr>
											)
										})}
									</tbody>
								</table>
							}
						</React.Fragment>
					)
				})}
			</div>
		)
	}
}

export default CrowAdminRelationOTM;