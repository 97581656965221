import React, { useState } from 'react';

function CrowFieldRadio(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { data = [], htmlattr } = attr || [];

	if (!data || (Array.isArray(data) && data.length === 0)) {
		// load dynamic data
	}

	if (typeof data === 'string') {
		// AJAX call to fetch data
	}

	if (!value) {
		value = '';
	}

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			{data.map((entry, i) => {
				let checked = false;
				if (entry.val === value) {
					checked = true;
				}

				return (
					<div key={i}>
						<input
							type='radio'
							label={entry.key}
							name={field.name}
							value={entry.val}
							onChange={(e) => {
								props.setValue(e.target.value);
							}}
							checked={checked}
							{...htmlattr}
						/>
						<label htmlFor={entry.key}><span>{entry.key}</span></label>
					</div>
				)
			})}
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['choice.radio'] = CrowFieldRadio;
export default CrowFieldRadio;