import {
	camelCase,
	pascalCase,
	snakeCase
} from 'change-case';

const toLowerCamelCase = function (s) {
	return camelCase(s);
};

const toCamelCase = function (s) {
	return pascalCase(s);
};

const toSnakeCase = function (s) {
	return snakeCase(s);
};

const parseEntityName = function (name) {
	let s = name.split('.');

	let m1 = s[0];
	let m2;
	let id;
	let col;

	// m1.col
	if (s.length === 2) {
		col = s[1];
	}

	// m1.m2.col
	if (s.length === 3) {
		m2 = s[1];
		id = s[2];
	}

	// m1.m2.id.col
	if (s.length === 4) {
		m2 = s[1];
		id = s[2];
		col = s[3];
	}

	return {
		m1,
		m2,
		id: Number(id),
		col,
	}
}

const getModel1Name = function (s) {
	let { m1 } = parseEntityName(s);

	return m1;
};

const getModel2Name = function (s) {
	let { m2 } = parseEntityName(s);

	return m2;
};

export {
	toLowerCamelCase,
	toCamelCase,
	toSnakeCase,

	parseEntityName,
	getModel1Name,
	getModel2Name,
};