import React from 'react';

function CrowFieldID(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { htmlattr } = attr || {};

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			<input
				type='hidden'
				value={value}
				onChange={(e) => {
					if (field.readonly) {
						return;
					}

					props.setValue(e.target.value);
				}}
				{...htmlattr}
			/>
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['hidden.id'] = CrowFieldID;
export default CrowFieldID;