import React from 'react';

function CrowFieldInt(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { htmlattr } = attr || {};

	if (!value || value === '') {
		value = 0;
	}

	return (
		<div className={`${props.classNameList.join(' ')}`}>
			<input
				type='number'
				value={value}
				onChange={(e) => {
					props.setValue(Number(e.target.value));
				}}
				{...htmlattr}
			/>
		</div>
	)
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['number.int'] = CrowFieldInt;
export default CrowFieldInt;