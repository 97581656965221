import React from 'react';

import Select from 'react-select';

import CrowFormEntity from 'Crow/Form/Entity.js';

import {
	getModel1Name,
	getModel2Name,
	parseEntityName,
} from 'Crow/Common/Crow';

class CrowAdminRelationOTO extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			otoFields: props.value || [],
		};
	}

	componentDidMount() {
		console.log('OTO_MOUNT');
		console.log(this.props);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.otoFields !== this.props.otoFields) {
			debugger;

			this.setState({
				otoFields: this.props.otoFields,
			});
		}
	}

	handleCreateClick(field) {
		document.body.dispatchEvent(new CustomEvent('router::redirect', {
			detail: {
				entity: field,
			},
		}));
	}

	handleEditClick(field) {
		document.body.dispatchEvent(new CustomEvent('router::redirect', {
			detail: {
				edit: true,
				entity: field,
			},
		}));
	}

	renderOTO(key, field) {
		let {
			form,
		} = this.props;

		let { attr = {} } = field;
		let { data = [] } = attr;

		// no attr data provided so we
		// will fallback to relation_data
		if (data.length === 0) {
			let { m2 } = parseEntityName(field.name);
			data = form.state.form.flatForm.relation_data[m2];
		}

		let defaultValueIndex = false;
		let options = data.map((entry, i) => {
			if (field.value === entry.val) {
				defaultValueIndex = i;
			}

			return {
				value: entry.val,
				label: entry.key,
			}
		});

		let defaultOption = options[defaultValueIndex];

		let alias = "";
		if (getModel2Name(key) === form.props.form.m1) {
			alias = "(ALIAS)"
		}

		return (
			<React.Fragment>
				<div><strong>OTO &gt; {getModel2Name(key).toUpperCase()}{alias}:</strong></div>
				<div>
					<button
						onClick={this.handleCreateClick.bind(this, field)}
					>create</button>

					{Boolean(defaultValueIndex) &&
						<button
							onClick={this.handleEditClick.bind(this, field)}
						>edit '{defaultOption.label}'</button>
					}
				</div>

				{data.length > 0 &&
					<Select
						placeholder={`Choose a OTO Relation`}
						isSearchable={true}
						isClearable={true}
						defaultValue={defaultOption}
						options={options}
						onChange={(e, { action }) => {
							if (action === 'clear') {
								let deleteConfirmed = window.confirm("Delete Relation?");
								if (deleteConfirmed) {
									let tmp = key.split('.');
									let idCol = tmp.pop();
									tmp.push(defaultOption.value);
									tmp.push(idCol);

									form.setValue(tmp.join('.'), 'CROW::DELETE');
								} else {
									form.setValue(key, defaultOption.value);
								}

								return;
							}

							form.setValue(key, e.value);
						}}
					/>
				}
			</React.Fragment>
		)
	}

	render() {
		let {
			form,
		} = this.props;

		let hasOTORelation = {};

		return (
			<div
				style={{
					marginTop: '5px',
					padding: '5px',
					backgroundColor: '#efefef',
					border: '1px solid #333',
					// overflowX: 'scroll',
				}}
			>
				{Object.entries(this.state.otoFields).map((entry, i) => {
					let name = entry[0];
					let entity = entry[1];

					let m1m2 = name.substr(0, name.lastIndexOf('.'));
					if (~name.indexOf('_id')) {
						if (entity.value) {
							hasOTORelation[m1m2] = true;
						}

						return (
							<React.Fragment
								key={name}
							>
								{i !== 0 &&
									<hr />
								}
								{this.renderOTO(name, entity)}
							</React.Fragment>
						)
					}

					if (!hasOTORelation[m1m2]) {
						return '';
					}

					// m2.attr
					return (
						<React.Fragment
							key={name}
						>
							<CrowFormEntity
								form={form}
								entity={entity}
								getValue={() => {
									return form.getValue(name);
								}}
								setValue={(name, value) => {
									let { m1, m2 } = parseEntityName(name);

									let alias = m2;
									if (m1 == m2) {
										alias = "alias";
									}

									let id = `${alias}_id`;
									let otoName = `${m1}.${m2}.${id}`;
									form.setValue(otoName, form.getValue(otoName), true);

									return form.setValue(name, value);
								}}
								getStatus={this.props.getStatus}
								setStatus={this.props.setStatus}
							/>
						</React.Fragment>
					)
				})}
			</div>
		)
	}
}

export default CrowAdminRelationOTO;